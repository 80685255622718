<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=0 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">修改密码<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :model="user" :label-position="labelPosition" :rules="rules" label-width="100px"
                    class="elform" ref="elForm">
                    <el-form-item label="原密码" prop="OldPwd">
                      <el-input type="password" v-model="user.OldPwd"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="NewPwd">
                      <el-input type="password" v-model="user.NewPwd"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="ReNewPwd">
                      <el-input type="password" v-model="user.ReNewPwd"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elForm')">提交</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import userleft from '@/components/userleft.vue';
import { userusermodifypwd } from '@/api/user';
import Cookies from 'js-cookie';
import {removeCookieByName} from '@/utils/helper';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    return {
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      user: {
        OldPwd: '',
        NewPwd: '',
        NewPwd: '',
      },
      rules: {
        OldPwd: [{ required: true, message: '请输入原密码' }],
        NewPwd: [
          { required: true, message: '请输入新密码' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符' },
        ],
        ReNewPwd: [
          { required: true, message: '请确认新密码' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符' },
        ],
      },
    };
  },
  methods: {
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    onsubmitForm(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          userusermodifypwd(this.user).then((res) => {
            if (res.data.code === 1) {
              this.$message({
                message: '恭喜你，提交成功',
                type: 'success',
              });
              this.removeCookies();
              setTimeout(function () {
                window.location.href="/"
              }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeCookies() {
      removeCookieByName('access_token');
      removeCookieByName('user_account');
      removeCookieByName('refresh_token');
      removeCookieByName('user_id');
      removeCookieByName('iscompanyauthsuccess');
    },
  },
  mounted() {
    screenLeft;
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width:940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
</style>
